export var basicColors = [
    "red",
    "pink",
    "blue",
    "green",
    "yellow",
    "orange",
    "purple",
    "brown",
];
