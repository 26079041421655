var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Paper, Typography } from "@mui/material";
var StatisticsCard = function (_a) {
    var title = _a.title, value = _a.value, description = _a.description, _b = _a.isVerticalScreen, isVerticalScreen = _b === void 0 ? false : _b;
    return (_jsxs(Paper, __assign({ sx: {
            height: "100%",
            my: 0,
            background: "#8BC53F",
            alignContent: "center",
        } }, { children: [_jsx(Typography, __assign({ variant: "h6", align: "center", sx: {
                    fontSize: isVerticalScreen ? "3vh" : "1.5vw",
                    fontFamily: "korolev",
                }, color: "#FFFFFF" }, { children: title })), _jsx(Typography, __assign({ variant: "h3", align: "center", sx: {
                    fontSize: isVerticalScreen ? "4vh" : "2vw",
                    fontFamily: "korolev",
                    fontWeight: "bold",
                }, color: "#FFFFFF" }, { children: value })), _jsx(Typography, __assign({ sx: {
                    fontStyle: "italic",
                    fontSize: isVerticalScreen ? "1.8vh" : "0.9vw",
                    fontFamily: "korolev",
                }, component: "h6", align: "center", color: "#FFFFFF" }, { children: description }))] })));
};
export default StatisticsCard;
