var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
var BetaTestingBar = function () {
    var link = "http://" + window.location.host + "/feedback";
    return (_jsxs(Box, __assign({ sx: {
            flex: 1,
            backgroundColor: "#8BC53F",
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5vh",
        } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "2vw",
                    marginBottom: "4vh",
                    fontFamily: "korolev",
                } }, { children: "MSP app is currently in beta testing and will soon be available on the App Store and Playstore" })), _jsxs(Box, __assign({ sx: {
                    textAlign: "center",
                    marginBottom: "4vh",
                } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                            fontSize: "1.5vw",
                            marginBottom: "2vh",
                            fontFamily: "korolev",
                        } }, { children: "Please scan QR code for any feedback and suggestions" })), _jsx("div", __assign({ style: {
                            height: "auto",
                            margin: "0 auto",
                            maxWidth: "50%",
                            width: "100%",
                        } }, { children: _jsx(QRCode, { bgColor: "#8BC53F", fgColor: "#ffffff", size: 256, style: { height: "auto", maxWidth: "100%", width: "100%" }, value: link }) }))] }))] })));
};
export default BetaTestingBar;
