import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { MatchState } from "shared/models/matchModels";
import TeamMatchLobby from "./teammatchlobby";
import TeamInfo from "./teaminfo";
import TeamPostMatch from "./teampostmatch";
var MatchTeam = function (_a) {
    var matchData = _a.matchData;
    switch (matchData.state) {
        case MatchState.NEW:
            return _jsx(TeamMatchLobby, { data: matchData });
        case MatchState.READY:
        case MatchState.IN_PROGRESS:
            return _jsx(TeamInfo, { data: matchData });
        case MatchState.FINISHED:
            return _jsx(TeamPostMatch, { data: matchData });
    }
};
export default MatchTeam;
