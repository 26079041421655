var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import Logo from "@/components/Logo";
import { MatchFormat } from "shared/models/matchModels";
import BetaTestingBar from "@/components/BetaTestingBar";
var SingleMatchLobby = function (_a) {
    var data = _a.data;
    return (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            display: "flex",
            flexDirection: "row",
        } }, { children: [_jsx(BetaTestingBar, {}), _jsxs(Box, __assign({ sx: {
                    flex: 4,
                    backgroundColor: "#f0f4ea",
                    padding: "5vh",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: [_jsx(Box, __assign({ sx: { textAlign: "center", marginBottom: "4vh" } }, { children: _jsx(Logo, { width: "20%", height: "auto" }) })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: {
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "stretch",
                        } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "2vh", // Space between the two stacked cards
                                } }, { children: [_jsxs(Paper, __assign({ elevation: 0, sx: {
                                            backgroundColor: "#8BC53F",
                                            color: "#FFFFFF",
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                    fontWeight: "bold",
                                                    fontSize: "3vw",
                                                    marginBottom: "0vh",
                                                    fontFamily: "korolev",
                                                } }, { children: data.sessionName })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: ["Number of Players: ", data.players] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: ["Match Format:", " ", data.format.charAt(0).toUpperCase() +
                                                        data.format.toLowerCase().slice(1)] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: [data.format === MatchFormat.TIMED
                                                        ? "Time Per Round:"
                                                        : "Points to Win:", " ", data.limit, " ", data.format === MatchFormat.TIMED ? "Minutes" : "Points"] }))] })), _jsx(Paper, __assign({ elevation: 0, sx: {
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: _jsxs(Typography, __assign({ variant: "h5", sx: {
                                                marginTop: "2vh",
                                                lineHeight: "5vh",
                                                fontSize: "2vw",
                                                fontFamily: "korolev",
                                            } }, { children: [data.format === MatchFormat.POINTS
                                                    ? "- End round at first team to ".concat(data.limit, " points")
                                                    : "- Switch after every ".concat(data.limit, " minutes"), _jsx("br", {}), "- 2 serves per person (alternate)", _jsx("br", {}), "- Points increment by 1", _jsx("br", {}), "- Submit score after every round"] })) }))] })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                } }, { children: _jsxs(Paper, __assign({ elevation: 0, sx: {
                                        padding: "3vh",
                                        borderRadius: "1vw",
                                        textAlign: "left",
                                        fontSize: "1.5vw",
                                        flex: 1,
                                        overflow: "hidden", // No vertical overflow
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                // fontWeight: "bold",
                                                fontSize: "2vw",
                                                marginBottom: "2vh",
                                                fontFamily: "korolev",
                                            } }, { children: "Players" })), _jsx(Box, __assign({ sx: {
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                gap: "1.5vh",
                                                overflowY: "auto",
                                                padding: "1vh",
                                            } }, { children: data.playerList.map(function (player, index) { return (_jsx(Typography, __assign({ variant: "h5", sx: {
                                                    marginBottom: "1vh",
                                                    fontSize: "1.5vw",
                                                    textAlign: "left",
                                                    fontFamily: "korolev",
                                                } }, { children: player.name }), index)); }) }))] })) }))] }))] }))] })));
};
export default SingleMatchLobby;
