import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { listenToDisplayChange } from "@/dao/match";
import Landing from "./landing";
var Display = function () {
    var displayId = useParams().displayId;
    var _a = useState(null), displayData = _a[0], setDisplayData = _a[1];
    useEffect(function () {
        if (displayId) {
            var unsubscribe_1 = listenToDisplayChange(displayId, function (data) {
                setDisplayData(data);
            });
            return function () {
                unsubscribe_1();
            };
        }
    }, [displayId]);
    if (!displayId)
        return _jsx(Navigate, { to: "/display", replace: true });
    if (displayData) {
        return _jsx(Navigate, { to: "/match/".concat(displayData.matchId), replace: true });
    }
    else {
        return _jsx(Landing, { matchId: displayId });
    }
};
export default Display;
