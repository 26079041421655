var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, Typography, TableBody, } from "@mui/material";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import ArrowDownwardSharp from "@mui/icons-material/ArrowDownwardSharp";
export var ScoreTable = function (_a) {
    var playersScore = _a.playersScore, playerDatas = _a.playerDatas, averageScore = _a.averageScore, leastPointsPlayed = _a.leastPointsPlayed, mostPointsPlayed = _a.mostPointsPlayed, rounds = _a.rounds, extended = _a.extended, _b = _a.containerHeight, containerHeight = _b === void 0 ? "100vh" : _b, _c = _a.isVerticalScreen, isVerticalScreen = _c === void 0 ? false : _c;
    // Calculate row and header height
    var totalRows = 16; // Total rows
    var totalParts = totalRows + 1; // Rows + header
    var rowHeight = "calc(".concat(containerHeight, " / ").concat(totalParts, ")");
    var orientation = isVerticalScreen ? "vh" : "vw";
    return (_jsx(TableContainer, __assign({ component: Paper, sx: {
            height: containerHeight,
            // width: "40"+orientation,
            display: "flex",
            flexDirection: "column",
            border: "2px solid #8BC53F",
        } }, { children: _jsxs(Table, __assign({ sx: {
                tableLayout: "fixed",
                borderCollapse: "collapse",
                height: "100%",
            } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, __assign({ sx: { height: rowHeight } }, { children: [_jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                        fontSize: isVerticalScreen ? "1.5vh" : "1.5vw",
                                        fontWeight: "bold",
                                        fontFamily: "korolev",
                                        color: "#FFFFFF",
                                    }, noWrap: true }, { children: "PLAYER" })) })), _jsx(TableCell, __assign({ align: "center", padding: "none", sx: {
                                    background: "#8BC53F",
                                    border: "2px solid #8BC53F",
                                    width: "2" + orientation,
                                } }, { children: _jsx(Typography, { variant: "h6", sx: {
                                        fontSize: "1.5" + orientation,
                                        fontFamily: "korolev",
                                        color: "#FFFFFF",
                                    }, noWrap: true }) })), _jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                        fontSize: "1.5" + orientation,
                                        fontFamily: "korolev",
                                        color: "#FFFFFF",
                                    }, noWrap: true }, { children: "POINTS" })) })), extended && (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                fontSize: "1.5" + orientation,
                                                fontFamily: "korolev",
                                                color: "#FFFFFF",
                                            }, noWrap: true }, { children: "AVE" })) })), _jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                fontSize: "1.5" + orientation,
                                                fontFamily: "korolev",
                                                color: "#FFFFFF",
                                            }, noWrap: true }, { children: "WINS" })) })), _jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                fontSize: "1.5" + orientation,
                                                fontFamily: "korolev",
                                                color: "#FFFFFF",
                                            }, noWrap: true }, { children: "LOSS" })) })), _jsx(TableCell, __assign({ align: "center", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                fontSize: "1.5" + orientation,
                                                fontFamily: "korolev",
                                                color: "#FFFFFF",
                                            }, noWrap: true }, { children: "DRAW" })) }))] }))] })) }), _jsx(TableBody, { children: playersScore.map(function (player, index) {
                        var netScore = player.playerScore - player.pointsConceded;
                        var displayUpArrow = player.playerScore > averageScore &&
                            playersScore[index + 1].playerScore <= averageScore;
                        var displayDownArrow = player.playerScore < averageScore &&
                            playersScore[index - 1].playerScore >= averageScore;
                        var displayLeastPoints = leastPointsPlayed &&
                            player.playerIndex === leastPointsPlayed.playerIndex;
                        var displayMostPoints = mostPointsPlayed &&
                            player.playerIndex === mostPointsPlayed.playerIndex;
                        var rowColor = displayLeastPoints
                            ? "#6aa0d9"
                            : displayMostPoints
                                ? "#ebb671"
                                : "#FFFFFF";
                        return (_jsxs(TableRow, __assign({ sx: { height: rowHeight, background: rowColor } }, { children: [_jsx(TableCell, __assign({ align: "left", padding: "none", sx: { background: "#8BC53F", border: "2px solid #8BC53F" } }, { children: playerDatas[player.playerIndex].name
                                        .split("&", 2)
                                        .map(function (name, i) { return (_jsx(Typography, __assign({ sx: { fontSize: isVerticalScreen ? "1.8vh" : "1.2vw" }, noWrap: true, color: "#FFFFFF" }, { children: name }), i)); }) })), _jsx(TableCell, __assign({ align: "left", padding: "none" }, { children: _jsx(Typography, __assign({ sx: {
                                            fontSize: "1.2" + orientation,
                                            paddingLeft: "0.3" + orientation,
                                        }, noWrap: true }, { children: displayUpArrow ? (_jsx(ArrowUpwardSharpIcon, { style: { color: "green" } })) : displayDownArrow ? (_jsx(ArrowDownwardSharp, { style: { color: "red" } })) : ("") })) })), _jsx(TableCell, __assign({ align: "left", padding: "none" }, { children: _jsxs(Typography, __assign({ sx: {
                                            fontSize: isVerticalScreen ? "1.6vh" : "1.2vw",
                                            paddingLeft: "0.3" + orientation,
                                        }, noWrap: true }, { children: [player.playerScore, " ", extended && (_jsxs("span", __assign({ style: { color: netScore >= 0 ? "green" : "red" } }, { children: ["(", netScore >= 0 ? "+" : "", netScore, ")"] })))] })) })), extended && (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ align: "center", padding: "none" }, { children: _jsx(Typography, __assign({ sx: { fontSize: isVerticalScreen ? "1.6vh" : "1.2vw" }, noWrap: true }, { children: Math.round((player.playerScore / (rounds || 1)) * 100) / 100 })) })), _jsx(TableCell, __assign({ align: "center", padding: "none" }, { children: _jsx(Typography, __assign({ sx: { fontSize: isVerticalScreen ? "1.6vh" : "1.2vw" }, noWrap: true }, { children: player.gamesWon })) })), _jsx(TableCell, __assign({ align: "center", padding: "none" }, { children: _jsx(Typography, __assign({ sx: { fontSize: isVerticalScreen ? "1.6vh" : "1.2vw" }, noWrap: true }, { children: player.gamesLost })) })), _jsx(TableCell, __assign({ align: "center", padding: "none" }, { children: _jsx(Typography, __assign({ sx: { fontSize: isVerticalScreen ? "1.6vh" : "1.2vw" }, noWrap: true }, { children: player.gamesDraw })) }))] }))] }), index));
                    }) })] })) })));
};
