import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { MatchState } from "shared/models/matchModels";
import SingleMatchLobby from "./singlematchlobby";
import SingleInfo from "./singleinfo";
import SinglePostMatch from "./singlepostmatch";
var MatchSingle = function (_a) {
    var matchData = _a.matchData;
    switch (matchData.state) {
        case MatchState.NEW:
            return _jsx(SingleMatchLobby, { data: matchData });
        case MatchState.READY:
        case MatchState.IN_PROGRESS:
            return _jsx(SingleInfo, { data: matchData });
        case MatchState.FINISHED:
            return _jsx(SinglePostMatch, { data: matchData });
    }
};
export default MatchSingle;
