var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import Logo from "./Logo";
var LogoDisplay = function (_a) {
    var sessionName = _a.sessionName, isLargeScreen = _a.isLargeScreen;
    return !isLargeScreen ? (_jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "center", paddingTop: 5 }, { children: [_jsx(Grid, __assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Logo, { width: "70%", height: "70%" }) })), _jsx(Grid, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true, sx: { textAlign: "center" } }, { children: _jsx("h1", { children: sessionName }) })) })] }))) : (_jsxs(Grid, __assign({ container: true, alignItems: "center", sx: { height: "15vh" } }, { children: [_jsx(Grid, __assign({ item: true, xs: 4, sm: 3, md: 2, display: "flex", justifyContent: { xs: "center", sm: "flex-start" } }, { children: _jsx(Logo, { width: "70%", height: "70%" }) })), _jsx(Grid, __assign({ item: true, xs: 8, sm: 6, md: 8, display: "flex", justifyContent: "center" }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true, sx: { textAlign: "center" } }, { children: _jsx("h1", { children: sessionName }) })) })), _jsx(Grid, { item: true, xs: false, sm: 3, md: 2 })] })));
};
export default LogoDisplay;
