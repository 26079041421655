var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import QRCode from "react-qr-code";
import Logo from "@/components/Logo";
import { MatchFormat } from "shared/models/matchModels";
import { basicColors } from "@/utils/colors";
var TeamMatchLobby = function (_a) {
    var data = _a.data;
    var link = "http://" + window.location.host + "/feedback";
    return (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            display: "flex",
            flexDirection: "row",
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    flex: 1,
                    backgroundColor: "#8BC53F",
                    color: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5vh",
                } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "2vw",
                            marginBottom: "4vh",
                            fontFamily: "korolev",
                        } }, { children: "MSP app is currently in beta testing and will soon be available on the App Store and Playstore" })), _jsxs(Box, __assign({ sx: {
                            textAlign: "center",
                            marginBottom: "4vh",
                        } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                                    fontSize: "1.5vw",
                                    marginBottom: "2vh",
                                    fontFamily: "korolev",
                                } }, { children: "Please scan QR code for any feedback and suggestions" })), _jsx("div", __assign({ style: {
                                    height: "auto",
                                    margin: "0 auto",
                                    maxWidth: "50%",
                                    width: "100%",
                                } }, { children: _jsx(QRCode, { bgColor: "#8BC53F", fgColor: "#ffffff", size: 256, style: { height: "auto", maxWidth: "100%", width: "100%" }, value: link }) }))] }))] })), _jsxs(Box, __assign({ sx: {
                    flex: 4,
                    backgroundColor: "#f0f4ea",
                    padding: "5vh",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: [_jsx(Box, __assign({ sx: { textAlign: "center", marginBottom: "4vh" } }, { children: _jsx(Logo, { width: "20%", height: "auto" }) })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: {
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "stretch",
                        } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "2vh", // Space between the two stacked cards
                                } }, { children: [_jsxs(Paper, __assign({ elevation: 0, sx: {
                                            backgroundColor: "#8BC53F",
                                            color: "#FFFFFF",
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                    fontWeight: "bold",
                                                    fontSize: "3vw",
                                                    marginBottom: "0vh",
                                                    fontFamily: "korolev",
                                                } }, { children: data.sessionName })), _jsx(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: "Match Mode: Teamed" })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: ["Number of Teams: ", data.players / 2] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: ["Match Format:", " ", data.format.charAt(0).toUpperCase() +
                                                        data.format.toLowerCase().slice(1)] })), _jsxs(Typography, __assign({ variant: "h5", sx: { fontSize: "2.5vw", fontFamily: "korolev" } }, { children: [data.format === MatchFormat.TIMED
                                                        ? "Time Per Round:"
                                                        : "Points to Win:", " ", data.limit, " ", data.format === MatchFormat.TIMED ? "Minutes" : "Points"] }))] })), _jsx(Paper, __assign({ elevation: 0, sx: {
                                            padding: "3vh",
                                            borderRadius: "1vw",
                                            textAlign: "left",
                                            fontSize: "1.5vw",
                                            flex: 1, // Ensure this card takes up equal space
                                        } }, { children: _jsxs(Typography, __assign({ variant: "h5", sx: {
                                                marginTop: "2vh",
                                                lineHeight: "5vh",
                                                fontSize: "2vw",
                                                fontFamily: "korolev",
                                            } }, { children: ["- Game Length: ".concat(data.limit, " minutes"), _jsx("br", {}), "- Teams of 2", _jsx("br", {}), "- Points increment by 1", _jsx("br", {}), "- Submit score after every round"] })) }))] })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                } }, { children: _jsxs(Grid
                                //   elevation={0}
                                , __assign({ 
                                    //   elevation={0}
                                    sx: {
                                        padding: "3vh",
                                        // borderRadius: "1vw",
                                        textAlign: "left",
                                        fontSize: "1.5vw",
                                        flex: 1,
                                        overflow: "hidden", // No vertical overflow
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                // fontWeight: "bold",
                                                fontSize: "2vw",
                                                marginBottom: "2vh",
                                                fontFamily: "korolev",
                                            } }, { children: "Players" })), _jsx(Box, __assign({ sx: {
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                gap: "1.5vh",
                                                overflowY: "auto",
                                                padding: "1vh",
                                            } }, { children: data.playerList.map(function (player, index) {
                                                return index % 2 === 0 ? null : (_jsxs(Paper, __assign({ sx: {
                                                        position: "relative",
                                                        padding: "1.5vh",
                                                        borderRadius: "1vw",
                                                        overflow: "hidden", // Ensures background stays within rounded corners
                                                    } }, { children: [_jsx(Box, { sx: {
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                                width: "10%",
                                                                height: "100%",
                                                                backgroundColor: basicColors[(index - 1) / 2], // Replace with your desired color
                                                            } }), _jsx(Typography, __assign({ variant: "h5", sx: {
                                                                fontSize: "2vw",
                                                                textAlign: "left",
                                                                fontFamily: "korolev",
                                                            } }, { children: player.name }), index), _jsx(Typography, __assign({ variant: "h5", sx: {
                                                                fontSize: "2vw",
                                                                textAlign: "left",
                                                                fontFamily: "korolev",
                                                            } }, { children: data.playerList[index - 1].name }), index)] }), index));
                                            }) }))] })) }))] }))] }))] })));
};
export default TeamMatchLobby;
