var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography, Box, Grid, Paper, useMediaQuery } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { useTheme } from "@mui/material/styles";
import { calculateAveragePointsOverall, calculateAveragePointsPerGame, calculatePlayerScores, findPlayerWithLeastPlayedPoints, findPlayerWithMostPlayedPoints, getGameWithHighestPointDifference, preparePlayerChartData, } from "shared/utils/logic";
import { ScoreTable } from "@/components/ScoreTable";
import LogoDisplay from "@/components/LogoDisplay";
import QRCodeDisplay from "@/components/QRCodeDisplay";
import StatisticsCard from "@/components/StatisticsCard";
var TeamPostMatch = function (_a) {
    var data = _a.data;
    var theme = useTheme();
    var isPhone = !useMediaQuery(theme.breakpoints.up("sm"));
    var filteredRounds = data.rounds.filter(function (x) {
        return !x.games.every(function (game) { return game.team1Score === 0 && game.team2Score === 0; });
    });
    var playersScore = calculatePlayerScores(filteredRounds);
    var tablePlayerLabels = [];
    data.playerList.forEach(function (x, i) {
        var name = i % 2 === 0 ? x.name + " & " + data.playerList[i + 1].name : x.name;
        tablePlayerLabels.push(__assign(__assign({}, x), { name: name }));
    });
    var chartData = preparePlayerChartData(filteredRounds, tablePlayerLabels.map(function (x) { return x.name; }));
    var highGameDiff = getGameWithHighestPointDifference(filteredRounds);
    var lowestGamesPlayed = findPlayerWithLeastPlayedPoints(filteredRounds);
    var mostGamesPlayed = findPlayerWithMostPlayedPoints(filteredRounds);
    var totalAverage = calculateAveragePointsPerGame(filteredRounds);
    var totalOverallAverage = calculateAveragePointsOverall(playersScore);
    var xAxis = __spreadArray(__spreadArray([], filteredRounds.map(function (v, i) { return i; }), true), [filteredRounds.length], false);
    return (_jsxs(Box, __assign({ sx: { paddingX: 1, paddingY: 0, backgroundColor: "#f0f4ea" } }, { children: [_jsx(LogoDisplay, { sessionName: data.sessionName, isLargeScreen: !isPhone }), _jsxs(Grid, __assign({ container: true, spacing: 2, alignItems: "stretch" }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 5 }, { children: _jsx(Paper, __assign({ elevation: 0, sx: {
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            } }, { children: _jsx(ScoreTable, { playersScore: playersScore.filter(function (x, i) { return i % 2 === 0; }), playerDatas: tablePlayerLabels, averageScore: totalOverallAverage, leastPointsPlayed: lowestGamesPlayed, mostPointsPlayed: mostGamesPlayed, extended: true, rounds: data.rounds.length, containerHeight: "82.5vh", isVerticalScreen: isPhone }) })) })), _jsxs(Grid, __assign({ item: true, xs: 12, md: 7, container: true, spacing: 1, sx: { height: "84vh" } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Paper, __assign({ sx: {
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        border: "2px solid #8BC53F",
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h6", component: "h2", align: "center" }, { children: "Points Over Time" })), _jsx("div", __assign({ style: { height: "47vh" } }, { children: _jsx(LineChart, { xAxis: [{ data: xAxis, tickMinStep: 1, label: "Round" }], yAxis: [
                                                    {
                                                        label: "Points",
                                                        max: playersScore[0].playerScore,
                                                    },
                                                ], series: chartData.filter(function (x, i) { return i % 2 === 0; }), margin: { top: isPhone ? 130 : 80, right: 20 }, grid: { vertical: true, horizontal: true }, slotProps: {
                                                    legend: {
                                                        direction: "row",
                                                        itemMarkWidth: 15,
                                                        itemMarkHeight: 15,
                                                        markGap: 5,
                                                        itemGap: 10,
                                                    },
                                                } }) }))] })) })), _jsxs(Grid, __assign({ item: true, xs: 12, container: true, spacing: 1, sx: { height: "30vh" } }, { children: [_jsxs(Grid, __assign({ item: true, container: true, xs: 12, md: 9, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(StatisticsCard, { title: "Highest Game Point Diff", value: (highGameDiff === null || highGameDiff === void 0 ? void 0 : highGameDiff.pointDifference) || 0, description: "Round ".concat(highGameDiff.roundIndex + 1, " (").concat(highGameDiff === null || highGameDiff === void 0 ? void 0 : highGameDiff.game.team1Score, ") ").concat(highGameDiff === null || highGameDiff === void 0 ? void 0 : highGameDiff.game.team1.map(function (x) { return data.playerList[x].name; }).join(" & "), " vs ").concat(highGameDiff === null || highGameDiff === void 0 ? void 0 : highGameDiff.game.team2.map(function (x) { return data.playerList[x].name; }).join(" & "), " (").concat(highGameDiff === null || highGameDiff === void 0 ? void 0 : highGameDiff.game.team2Score, ")"), isVerticalScreen: isPhone }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(StatisticsCard, { title: "Average Score Per Game", value: totalAverage, description: "".concat(playersScore.filter(function (x) { return x.playerScore / data.rounds.length > totalAverage; }).length, " player(s) exceeded the average"), isVerticalScreen: isPhone }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(StatisticsCard, { title: "Least Points Played", value: (lowestGamesPlayed === null || lowestGamesPlayed === void 0 ? void 0 : lowestGamesPlayed.totalPoints) || 0, description: data.playerList[lowestGamesPlayed.playerIndex].name +
                                                        " & " +
                                                        data.playerList[lowestGamesPlayed.playerIndex + 1].name, isVerticalScreen: isPhone }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6, paddingBottom: isPhone ? 2 : 0 }, { children: _jsx(StatisticsCard, { title: "Most Points Played", value: (mostGamesPlayed === null || mostGamesPlayed === void 0 ? void 0 : mostGamesPlayed.totalPoints) || 0, description: data.playerList[mostGamesPlayed.playerIndex].name +
                                                        " & " +
                                                        data.playerList[mostGamesPlayed.playerIndex + 1].name, isVerticalScreen: isPhone }) }))] })), _jsx(Grid, __assign({ item: true, container: true, xs: 0, md: 3, spacing: 1 }, { children: isPhone ? null : (_jsx(Grid, __assign({ item: true, md: 12 }, { children: _jsx(QRCodeDisplay, {}) }))) }))] }))] }))] }))] })));
};
export default TeamPostMatch;
