var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import QRCode from "react-qr-code";
var QRCodeDisplay = function () {
    return (_jsx(Paper, __assign({ sx: { height: "100%", my: 1 } }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 12, container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 12, padding: 1 }, { children: _jsx(Box, __assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(QRCode, { value: window.location.href, size: window.innerWidth / 10 }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", component: "h2", align: "center", sx: { fontFamily: "korolev", fontSize: "1.2vw" } }, { children: "SCAN QR FOR THIS PAGE" })) }))] })) })));
};
export default QRCodeDisplay;
